import React, { useEffect, useState, useContext } from "react";
import Map from "../Map";
import MapMarketViewer from "../MapMarketViewer";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import MapBehaviorToggle from "../MapBehaviorToggle/MapBehaviorToggle";
import AreaListContainer from "../../containers/AreaListContainer";
import MapContext from "./MapContext";
import { selectZip } from "../../redux/zipActions";
import { selectMarket } from "../../redux/marketActions";

const MapParent = ({ userInfo }) => {
  const dispatch = useDispatch();
  const { mapRef } = useContext(MapContext);
  const mapBehavior = useSelector((state) => state.map?.mapBehavior);
  const [showComponents, setShowComponents] = useState(false);
  const [key, setKey] = useState(mapBehavior); // State to force re-render

  const handleEventTrigger = () => {
    setShowComponents(true);
  };

  useEffect(() => {
    if (mapBehavior) {
      setShowComponents(false);
    }

    return () => {
      if (mapRef && mapRef.current) {
        dispatch(selectZip(null));
        dispatch(selectMarket(null));
        mapRef.current = null; // Reset mapRef on unmount
      }
    };
  }, [mapBehavior, mapRef, dispatch]);

  useEffect(() => {
    setKey(mapBehavior); // Update key to force re-render when mapBehavior changes
  }, [mapBehavior]);

  return (
    <div className="map-parent">
      {showComponents && <MapBehaviorToggle userInfo={userInfo} />}
      {showComponents && mapBehavior === "edit" && (
        <>
          <AreaListContainer userInfo={userInfo} />
        </>
      )}
      {mapBehavior !== "edit" ? (
        <MapMarketViewer
          key={key + 'mapMarket'} // Force re-render by changing the key
          userInfo={userInfo}
          onEventTrigger={handleEventTrigger}
        />
      ) : (
        <Map
          key={key + 'mapComplete'} // Force re-render by changing the key
          userInfo={userInfo}
          onEventTrigger={handleEventTrigger}
        />
      )}
    </div>
  );
};

MapParent.propTypes = {
  userInfo: PropTypes.object,
};

export default MapParent;
