import React, { useState } from "react";
import { UPDATE_AREA_SALES_MANAGER } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const SalesManagerSelect = ({ areaId, salesManagerId }) => {
  const [salesManager, setSalesManagerId] = useState(salesManagerId);

  const salesManagersData = useSelector((state) => state.agents?.salesmanagers);

  const [updateAreaSalesManager, { loading: updatingSalesManager }] =
    useMutation(UPDATE_AREA_SALES_MANAGER);

  const handleChange = (e) => {
    const newSalesManagerId = parseInt(e.target.value, 10);

    try {
      updateAreaSalesManager({
        variables: {
          areaId: areaId,
          salesManagerId: newSalesManagerId,
        },
      });
      setSalesManagerId(newSalesManagerId);
    } catch (error) {
      console.error("Error updating the area sales manager:", error);
    }
  };

  return (
    <div style={{ marginLeft: "10px" }}>
      Manager:
      <select
        value={salesManager ?? ""}
        onChange={handleChange}
        disabled={updatingSalesManager}
      >
        <option value="" disabled>
          Select a Sales Manager
        </option>
        { (
          salesManagersData?.map((manager) => (
            <option key={manager.id} value={manager.id}>
              {manager.name}
            </option>
          ))
        )}
      </select>
    </div>
  );
};

SalesManagerSelect.propTypes = {
  areaId: PropTypes.number,
  salesManagerId: PropTypes.number,
};

export default SalesManagerSelect;
