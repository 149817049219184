import React, { useEffect, useRef, useContext, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line
import "mapbox-gl/dist/mapbox-gl.css";
import styled from "styled-components";
import Popup from "./Popup";
import PopupContent from "./PopupContent";
import { ACCESS_TOKEN } from "./mapboxConstants";
import { flyToZip } from "../Map/mapUtility";
import { useDispatch, useSelector } from "react-redux";
import { initMap } from "../../redux/mapActions"; // Import necessary actions
import { selectMarket } from "../../redux/marketActions"; // Import necessary actions
import MapContext from "../MapParent/MapContext";
import PropTypes from 'prop-types';

const StyledContainer = styled.div`
  width: 100%;
  height: 100vh;
  min-width: 600px;
`;

const marketShapesId = "atm.market_shapes";
const communityPointsId = "background-points";

const MapMarketViewer = ({ userInfo, onEventTrigger }) => {
  const dispatch = useDispatch();

  const [popupLngLat, setPopupLngLat] = useState(null);
  const selectedZip = useSelector((state) => state.zips?.selectedZip);

  
  const mapRef = useContext(MapContext);
  const mapContainer = useRef(null);

  const vectorServer = process.env.REACT_APP_MAP_TILE_HOST;
  const marketShapesSourceURL = `${vectorServer}${marketShapesId}/{z}/{x}/{y}.pbf`;
  const communityPointsUrl = `${vectorServer}${communityPointsId}/{z}/{x}/{y}.pbf`;

  

  useEffect(() => {
    if (selectedZip != null && mapRef.current) {
      flyToZip(selectedZip, mapRef);
    }
  }, [selectedZip, mapRef]);

  const addMarketShapes = (mapInstance) => {
    mapInstance.addSource(marketShapesId, {
      type: "vector",
      tiles: [marketShapesSourceURL],
      minzoom: 0,
      maxzoom: 22,
    });

    mapInstance.addLayer({
      id: marketShapesId,
      source: marketShapesId,
      "source-layer": marketShapesId,
      type: "fill",
      paint: {
        "fill-color": {
          type: "identity",
          property: "color",
        },
        "fill-opacity": 0.4,
      },
    });
  };

  const addCommunityPoints = (mapInstance) => {
    mapInstance.addSource(communityPointsId, {
      type: "vector",
      tiles: [communityPointsUrl],
      minzoom: 0,
      maxzoom: 22,
      promoteId: "area_id",
    });

    mapInstance.addLayer({
      id: communityPointsId,
      source: communityPointsId,
      "source-layer": communityPointsId,
      type: "circle",
      paint: {
        "circle-color": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          "#19d14a",
          "#e00b0b",
        ],
        "circle-radius": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          7,
          5,
        ],
        "circle-stroke-color": "white",
        "circle-stroke-width": 1,
      },
    });
  };

  let hoveredCommunity = null;

  const mouseover = (mapInstance, feature) => {
    hoveredCommunity = feature;
    mapInstance.getCanvas().style.cursor = "pointer";
    mapInstance.setFeatureState(
      {
        source: communityPointsId,
        sourceLayer: communityPointsId,
        id: feature.id,
      },
      {
        hover: true,
      }
    );
  };

  const mouseout = (mapInstance) => {
    if (
      mapInstance.getFeatureState({
        source: communityPointsId,
        sourceLayer: communityPointsId,
        id: hoveredCommunity.id,
      })
    ) {
      mapInstance.setFeatureState(
        {
          source: communityPointsId,
          sourceLayer: communityPointsId,
          id: hoveredCommunity.id,
        },
        {
          hover: false,
        }
      );
    }
    mapInstance.getCanvas().style.cursor = "";
  };

  const addCommunityPointsEvents = (mapInstance) => {
    mapInstance.on("click", communityPointsId, (e) => {
      const coordinates = e.features[0].geometry.coordinates.slice(",");
      const communityName = e.features[0].properties.name;
      const propertyCount = e.features[0].properties.property_count;
      const popupContent = `<div class="map-community-popup-info">
                  <div><strong>${communityName}</strong></div>
                  <div>Property Count: ${propertyCount}</div>
              </div>`;

      new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(popupContent)
        .addTo(mapInstance);
    });

    mapInstance.on("click", (e) => {
      const features = mapInstance.queryRenderedFeatures(e.point);
      features.forEach((feature) => {
        if (feature.layer.id === marketShapesId) {
          dispatch(selectMarket(feature.properties["id"]));
        }
      });
    });

    mapInstance.on("mouseenter", communityPointsId, (e) => {
      if (e.features[0]) {
        mouseover(mapInstance, e.features[0]);
      } else {
        mouseout(mapInstance);
      }
    });

    mapInstance.on("mouseleave", communityPointsId, () => {
      mouseout(mapInstance);
    });
  };

  const initializeMap = () => {
    mapboxgl.accessToken = ACCESS_TOKEN;

    let newMap = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/light-v11",
      center: [-84.725034, 33.738045],
      zoom: 8,
    });

    newMap.on("load", () => {
      mapRef.current = newMap;
      dispatch(initMap(newMap.getCenter().toArray(), newMap.getZoom()));
      newMap.resize();

      addMarketShapes(newMap);
      addCommunityPoints(newMap);
      addCommunityPointsEvents(newMap);
      onEventTrigger();
    });
  };

  useEffect(() => {
    if (!mapRef.current) {
      initializeMap();
    }
  }, [mapRef]);

  return (
    <>
      {popupLngLat && (
        <Popup lngLat={popupLngLat}>
          <PopupContent data={popupLngLat.data} />
        </Popup>
      )}
      <StyledContainer ref={mapContainer} />
    </>
  );
}

MapMarketViewer.propTypes = {
  userInfo: PropTypes.object,
  onEventTrigger: PropTypes.func
}

export default MapMarketViewer;
