import React, { useState, useContext } from 'react';
import cn from 'classnames';
import MarketList from '../MarketList/MarketList';
import AddNewMarket from './AddNewMarket';
import TierPicker from './TierPicker';
import DeleteItem from '../Shared/DeleteItem';
import { useIsUserInGroup } from '../../oktaUtils';
import { MAP_EDITOR_GROUPNAME } from '../../utils';
import SalesManagerSelect from '../SalesManagerComponents/SalesManagerSelect';
import PropTypes from 'prop-types';
import { fetchZip } from "../Map/mapboxApi";
import MapContext from '../MapParent/MapContext';


const AreaPanel = ({ area , userInfo}) => {
  const [showMarketList, setShowMarketList] = useState(true);
  const [showAddNewMarket, setShowAddNewMarket] = useState(false);
  
  const map = useContext(MapContext);
  

  const [selectedAreaId, setSelectedAreaId] = useState(null);
  const isUserInMapEditGroup = useIsUserInGroup(MAP_EDITOR_GROUPNAME, userInfo);


  const handleAreaClick = (area) => {
    if (selectedAreaId === area.id) {
      setSelectedAreaId(null);
      map?.current.setFilter('assigned_markets-layer-selected', ['==', ['get', 'parent_area_id'], null]);
    } else {
      setSelectedAreaId(area.id);
      map?.current.setFilter('assigned_markets-layer-selected', ['==', ['get', 'parent_area_id'], area.id]);
      if (area?.markets.length > 0 && area.markets[0].zip_codes.length > 0) {
        let zip = area.markets[0].zip_codes[0];
        flyToZip(zip);
      }
    }
  };

  const flyToZip = async (zipIn) => {
    const zip = await fetchZip(zipIn.name);
    map?.current.flyTo({
      center: zip?.body.features[0].center,
      zoom: 9
    });
  };

  return (
    <div className="area-panel" id={'area-' + area.id}>
      <div className="area-panel-header-container">
      <h3 className={cn('area-panel-header', selectedAreaId === area.id)} onClick={() => handleAreaClick(area)}>
          {area.name}
          <SalesManagerSelect areaId={area.id} salesManagerId={area.sales_manager_id}></SalesManagerSelect>
          <TierPicker userInfo={userInfo} areaId={area.id} areaTier={area.tier} />
          <DeleteItem userInfo={userInfo} id={area.id} type='area' />
          {
            isUserInMapEditGroup ? (
              <button
                className={cn('btn-add-market', showAddNewMarket ? 'show' : 'hide')}
                onClick={() => setShowAddNewMarket(!showAddNewMarket)}
              />
            ) : null
          }
        </h3>
        <button
          className={cn('btn-collapse', showMarketList ? 'show' : 'hide')}
          onClick={() => setShowMarketList(!showMarketList)}
        />
      </div>
      {
        showAddNewMarket && <AddNewMarket userInfo={userInfo} area={area} />
      }
      {
        showMarketList && <MarketList userInfo={userInfo} areaId={area.id} markets={area.markets} />
      }
    </div>
  );
};

AreaPanel.propTypes = {
  area: PropTypes.object.isRequired,
  userInfo: PropTypes.object
}

export default AreaPanel;
